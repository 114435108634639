import { createAction } from 'redux-actions'
import * as types from './actionTypes'
import { getUserId } from '@/library/utils'

export const setAuthority = createAction(types.SET_AUTHORITY, (data: boolean) => ({
  data: {
    data
  }
}))

export const setModal = createAction(types.SET_MODAL, (visible: boolean) => ({
  data: {
    visible
  }
}))

export const setModalTitle = createAction(types.SET_MODAL_TITLE, (title: string) => ({
  data: {
    title
  }
}))

export const setDeleteId = createAction(types.SET_DELETE_ID, (id: number) => ({
  data: {
    id
  }
}))

export const setDeleteKrRemarkState = createAction(
  types.SET_DELETE_KR_REMARK_STATE,
  (state: boolean) => ({
    data: {
      state
    }
  })
)

export const setWeightModal = createAction(
  types.SET_WEIGHT_MODAL,
  (visible: boolean) => ({
    data: {
      visible
    }
  })
)

export const getWeightList = createAction(types.GET_WEIGHT_LIST, (objId: number) =>
  window.apis.getKeyReuslt({
    params: {
      obj_id: objId
    }
  })
)

export const setKrId = createAction(types.SET_KR_ID, (id: number) => ({
  data: {
    id
  }
}))

export const setAlignModal = createAction(types.SET_ALIGN_MODAL, (visible: boolean) => ({
  data: {
    visible
  }
}))

export const getAlignHistory = createAction(types.GET_ALIGN_HISTORY, (objId: number) =>
  window.apis.getAlignHistory({
    params: {
      obj_id: objId,
      visitor_id: getUserId()
    }
  })
)

export const setUpdateSide = createAction(types.SET_UPDATE_SIDE, (update: boolean) => ({
  data: {
    update
  }
}))

export const setObjId = createAction(types.SET_OBJ_ID, (objId: number) => ({
  data: {
    objId
  }
}))

export const setClearSearch = createAction(
  types.SET_ClEAR_SEARCH,
  (isClear: boolean) => ({
    data: {
      isClear
    }
  })
)

export const setUpdatePersonalBi = createAction(
  types.SET_UPDATE_PERSONALBI,
  (update: boolean) => ({
    data: {
      update
    }
  })
)

export const setKrStateList = createAction(
  types.SET_KR_STATE_LIST,
  (list: IKrStateList[]) => {
    const dictionary: { [key: number]: string } = {}
    list.forEach(item => {
      dictionary[item.id] = item.color
    })
    return {
      data: {
        list,
        dictionary
      }
    }
  }
)

export const setReopenHistoryCommentId = createAction(
  types.SET_REOPEN_HISTORY_COMMENT_ID,
  (id: IReopenHistoryCommentId) => ({
    data: {
      id
    }
  })
)

export const setHistoryCommentVisible = createAction(
  types.SET_HISTORY_COMMENT_VISIBLE,
  (visible: boolean) => ({
    data: {
      visible
    }
  })
)

export const setSuspensionVisible = createAction(
  types.SET_SUSPENSION_VISIBLE,
  (visible: boolean) => ({
    data: {
      visible
    }
  })
)

export const setClickSuspensionDataPanel = createAction(
  types.SET_CLICK_SUSPENSION_DATA_PANEL,
  (state: boolean) => ({
    data: {
      state
    }
  })
)

export const setPermissionObjId = createAction(
  types.SET_PERMISSION_OBJ_ID,
  (id: number) => ({
    data: {
      id
    }
  })
)

export const setPermissionModalVisible = createAction(
  types.SET_PERMISSION_MODAL_VISIBLE,
  (visible: boolean) => ({
    data: {
      visible
    }
  })
)

import * as React from 'react'
import { withRouter } from 'react-router-dom'

const Login = withRouter(React.lazy(() => import('./pages/login')))

export default [
  {
    path: '/login',
    component: () => <Login />
  }
]

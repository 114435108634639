const serverMap: IServerMap = {
  baseServer: {
    baseMap: {
      localprod: 'https://localprod.pandateacher.com/ninth-studio-okr/backend',
      prod: 'https://prod.pandateacher.com/ninth-studio-okr/backend',
      stage: 'https://stage.pandateacher.com/ninth-studio-okr/backend',
      test: 'https://test.pandateacher.com/ninth-studio-okr/backend',
      dev: 'https://dev.pandateacher.com/ninth-studio-okr/backend',
      local: 'http://localhost:4320'
    },
    default: true
  }
}

export default serverMap

interface IBaseMap {
  [key: string]: any
  localprod: string
  prod: string
  stage: string
  test: string
  dev: string
  local: string
}

interface IConfig {
  default?: boolean
  baseURL?: string
  baseMap: IBaseMap
}

interface IServerMap {
  [key: string]: IConfig
}

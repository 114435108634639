import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { Popover } from 'antd'
import EvaluatePlugin from '@forchange/evaluate'

import Avatar from '../Avatar/Avatar'
import SearchName from '../Search/Search'
import { setCurrentTab, setUpdateOkr } from '@/store/menu/actions'
import { setClearSearch } from '@store/okr/actions'
import { to } from '@/library/utils'
import logoIcon from './assets/header-logo.svg'

import './header.less'

interface IProps {
  clearSearch: boolean
  periodId: number
  screenSize: IScreenSize
  setClearSearch: (isClear: boolean) => void
  setCurrentTab: (tab: string) => void
  setUpdateOkr: (update: boolean) => void
}

const Header = (props: IProps) => {
  const [userInfo, setUserInfo] = useState<any>({})
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)
  const [helpCenterInfo, setHelpCenterInfo] = useState<string>('')
  useEffect(() => {
    const userInfo: any = localStorage.getItem('user_info')
    userInfo && setUserInfo(JSON.parse(userInfo))
    getHelpCenterInfo()
  }, [])
  const getHelpCenterInfo = async () => {
    const [err, res] = await to(window.apis.getHelpInfo())
    if (!err) {
      setHelpCenterInfo(res.data.url)
    }
  }
  const helpCenter = () => {
    setPopoverVisible(false)
    window.open(helpCenterInfo)
  }
  const loginOut = () => {
    setPopoverVisible(false)
    EvaluatePlugin.hide()
    // 清除页面信息
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user_info')
    sessionStorage.clear()
    window.router.replace('/login')
  }
  const handleSearch = (params: IUser) => {
    if (params.id !== userInfo.id) {
      props.setCurrentTab('null')
    }
    window.router.push(`/okr/${params.id}/${props.periodId}`)
  }
  const avatarTitle = (
    <div className="user-info">
      <div className="info">
        <div className="name">{userInfo.name}</div>
        <div className="email">{userInfo.email}</div>
      </div>
    </div>
  )
  const avatarButton = (
    <>
      <div className="help-center" onClick={helpCenter}>
        <div className="help-center-icon" />
        帮助中心
      </div>
      <div className="login-out" onClick={loginOut}>
        <div className="login-out-icon" />
        退出登录
      </div>
    </>
  )
  const handleBackMyOkr = () => {
    props.setUpdateOkr(true)
    window.router.push(`/okr/${userInfo.id}/${props.periodId}`)
    props.setCurrentTab(`${userInfo.id}`)
    props.setClearSearch(!props.clearSearch)
  }
  return (
    <div styleName="okr-header">
      <img src={logoIcon} alt="" styleName="logo-icon" onClick={handleBackMyOkr} />
      <div styleName={`search-name ${props.screenSize}`}>
        <SearchName width={244} handleSearch={handleSearch} />
      </div>
      <Popover
        placement="bottomRight"
        title={avatarTitle}
        content={avatarButton}
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={visible => setPopoverVisible(visible)}
        getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
      >
        <div styleName="user-img">
          <Avatar avatar={userInfo.avatar} name={userInfo.name} />
          <div styleName="tarangle" />
        </div>
      </Popover>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  clearSearch: state.okr.clearSearch,
  periodId: state.menu.periodId,
  screenSize: state.resize.screenSize
})

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentTab: (tab: string) => dispatch(setCurrentTab(tab)),
  setClearSearch: (isClear: boolean) => dispatch(setClearSearch(isClear)),
  setUpdateOkr: (update: boolean) => dispatch(setUpdateOkr(update))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)

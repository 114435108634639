import { createAction } from 'redux-actions'
import * as types from './actionTypes'
import { getUserId } from '@library/utils'

export const setProgressTimeEnough = createAction(
  types.progressTimeEnough,
  (state: boolean) => {
    const user_id = getUserId()
    user_id &&
      state &&
      localStorage.setItem(`${types.progressTimeEnough}_${user_id}`, 'done')
    return {
      data: { state }
    }
  }
)

export const setProgressDrawer = createAction(types.progressDrawer, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.progressDrawer}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const setPartakeTimeEnough = createAction(
  types.partakeTimeEnough,
  (state: boolean) => {
    const user_id = getUserId()
    user_id &&
      state &&
      localStorage.setItem(`${types.partakeTimeEnough}_${user_id}`, 'done')
    return {
      data: { state }
    }
  }
)

export const setPartakeDrawer = createAction(types.partakeDrawer, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.partakeDrawer}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const clearTeamState = createAction(types.clearState, () => {
  const user_id = getUserId()
  localStorage.removeItem(`${types.progressTimeEnough}_${user_id}`)
  localStorage.removeItem(`${types.progressDrawer}_${user_id}`)
  localStorage.removeItem(`${types.partakeTimeEnough}_${user_id}`)
  localStorage.removeItem(`${types.partakeDrawer}_${user_id}`)
})

import { createAction } from 'redux-actions'
import * as types from './actionTypes'
import { getUserId } from '@library/utils'

export const setMapTimeEnough = createAction(types.mapTimeEnough, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.mapTimeEnough}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const setClickCard = createAction(types.clickCard, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.clickCard}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const setShowSub = createAction(types.showSub, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.showSub}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const clearMapState = createAction(types.clearState, () => {
  const user_id = getUserId()
  localStorage.removeItem(`${types.mapTimeEnough}_${user_id}`)
  localStorage.removeItem(`${types.clickCard}_${user_id}`)
  localStorage.removeItem(`${types.showSub}_${user_id}`)
})

export default {
  getEvaluateId: {
    url: '/api/feedback/scene',
    method: 'get'
  },
  getEvaluateContent: {
    url: '/api/feedback/template',
    method: 'get'
  },
  postEvaluate: {
    url: '/api/feedback',
    method: 'post'
  }
}

import React from 'react'

import './avatar.less'
interface IProps {
  avatar: string
  name: string
}

const Avatar = (props: IProps) => {
  const { avatar, name } = props
  return (
    <>
      {avatar?.length > 0 ? (
        <img src={avatar} alt="" className="avatar" />
      ) : (
        <div className="default-avatar">{name?.slice(-2)}</div>
      )}
    </>
  )
}

export default Avatar

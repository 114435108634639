export default {
  getOkrInfo: {
    url: '/api/okr/info',
    method: 'get'
  },
  getPeriod: {
    url: '/api/okr/periods',
    method: 'get'
  },
  postOkrInfo: {
    url: '/api/okr/objective',
    method: 'post'
  },
  putOkrInfo: {
    url: '/api/okr/objective',
    method: 'put'
  },
  deleteOkrInfo: {
    url: '/api/okr/objective',
    method: 'delete'
  },
  postKeyResult: {
    url: '/api/okr/key-result',
    method: 'post'
  },
  putKeyResult: {
    url: '/api/okr/key-result',
    method: 'put'
  },
  deleteKeyResult: {
    url: '/api/okr/key-result',
    method: 'delete'
  },
  getKeyReuslt: {
    url: '/api/okr/key-result',
    method: 'get'
  },
  postKeyResultWeight: {
    url: '/api/okr/key-result-bulk',
    method: 'post'
  },
  getSideInfo: {
    url: '/api/okr/side-info',
    method: 'get'
  },
  deleteAlignInfo: {
    url: '/api/align/info',
    method: 'delete'
  },
  getAlignHistory: {
    url: '/api/align/history',
    method: 'get'
  },
  getAlignInfo: {
    url: '/api/align/o-info',
    method: 'get'
  },
  postAlignInfo: {
    url: '/api/align/info',
    method: 'post'
  },
  getFollow: {
    url: '/api/interact/follow',
    method: 'get'
  },
  postFollow: {
    url: '/api/interact/follow',
    method: 'post'
  },
  deleteFollow: {
    url: '/api/interact/follow',
    method: 'delete'
  },
  getBiData: {
    url: '/api/bi/personal/statistics',
    method: 'get'
  },
  getBiCharts: {
    url: '/api/bi/personal/chart',
    method: 'get'
  },
  getTeamBiProgress: {
    url: '/api/bi/team/progress',
    method: 'get'
  },
  getTeamBiParticipation: {
    url: '/api/bi/team/participation',
    method: 'get'
  },
  // 获取评论 @ 的人员列表
  getDefaultAtMen: {
    url: '/api/interact/comment/at',
    method: 'get'
  },
  // 发送评论
  postComment: {
    url: '/api/interact/comment',
    method: 'post'
  },
  // 获取评论，回复
  getCommentReply: {
    url: '/api/interact/comment-reply',
    method: 'get'
  },
  // 发送评论
  postReply: {
    url: '/api/interact/reply',
    method: 'post'
  },
  // 标记解决
  postIsSolved: {
    url: '/api/interact/comment/is-solved',
    method: 'post'
  },
  // 删除评论
  deleteComment: {
    url: '/api/interact/comment',
    method: 'delete'
  },
  // 删除回复
  deleteReply: {
    url: '/api/interact/reply',
    method: 'delete'
  },
  // 修改评论
  putComment: {
    url: '/api/interact/comment',
    method: 'put'
  },
  // 点赞
  postLike: {
    url: '/api/interact/like',
    method: 'post'
  },
  // 修改回复
  putReply: {
    url: '/api/interact/reply',
    method: 'put'
  },
  // 获取对齐视图
  getAlignMap: {
    url: '/api/align/map',
    method: 'get'
  },
  postSortO: {
    url: '/api/okr/sort/o',
    method: 'post'
  },
  postSortKr: {
    url: '/api/okr/sort/kr',
    method: 'post'
  },
  getKrStateList: {
    url: '/api/okr/kr-state',
    method: 'get'
  },
  getInfoCard: {
    url: '/api/okr/user-card',
    method: 'get'
  },
  postLookOther: {
    url: '/api/okr/update-cache',
    method: 'post'
  },
  getKrRemark: {
    url: '/api/okr/remark',
    method: 'get'
  },
  postCreateKrRemark: {
    url: '/api/okr/remark',
    method: 'post'
  },
  putReviseKrRemark: {
    url: '/api/okr/remark',
    method: 'put'
  },
  deleteKrRemark: {
    url: '/api/okr/remark',
    method: 'delete'
  },
  getHistoryComment: {
    url: '/api/interact/comment/history',
    method: 'get'
  },
  postCancelSolved: {
    url: '/api/interact/comment/is-solved',
    method: 'delete'
  },
  getPermissionType: {
    url: '/api/okr/permission/option-list',
    method: 'get'
  },
  getDeptInfo: {
    url: '/api/okr/dept-list',
    method: 'get'
  },
  getPermissionInfo: {
    url: '/api/okr/permission',
    method: 'get'
  },
  postPermissionInfo: {
    url: '/api/okr/permission',
    method: 'post'
  }
}

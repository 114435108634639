import { handleActions } from 'redux-actions'
import * as types from './actionTypes'
import { IState } from './type'

const initialState: IState = {
  mapTimeEnough: false,
  clickCard: false,
  showSub: false,
  mapDuration: 30000
}

const reducer = handleActions(
  {
    [`${types.mapTimeEnough}`]: (state, action: any) => ({
      ...state,
      mapTimeEnough: action.payload.data.state
    }),
    [`${types.clickCard}`]: (state, action: any) => ({
      ...state,
      clickCard: action.payload.data.state
    }),
    [`${types.showSub}`]: (state, action: any) => ({
      ...state,
      showSub: action.payload.data.state
    }),
    [`${types.clearState}`]: state => ({
      ...state,
      mapTimeEnough: false,
      clickCard: false,
      showSub: false
    })
  },
  initialState
)

export default reducer

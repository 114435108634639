export const SET_AUTHORITY = 'SET_AUTHORITY'

export const SET_MODAL = 'SET_MODAL'

export const SET_MODAL_TITLE = 'SET_MODAL_TITLE'

export const SET_DELETE_ID = 'SET_DELETE_ID'

export const SET_DELETE_KR_REMARK_STATE = 'SET_DELETE_KR_REMARK_STATE'

export const SET_WEIGHT_MODAL = 'SET_WEIGHT_MODAL'

export const GET_WEIGHT_LIST = 'GET_WEIGHT_LIST'

export const SET_KR_ID = 'SET_KR_ID'

export const SET_ALIGN_MODAL = 'SET_ALIGN_MODAL'

export const GET_ALIGN_HISTORY = 'GET_ALIGN_HISTORY'

export const SET_UPDATE_SIDE = 'SET_UPDATE_SIDE'

export const SET_OBJ_ID = 'SET_OBJ_ID'

export const SET_ClEAR_SEARCH = 'SET_ClEAR_SEARCH'

export const SET_UPDATE_PERSONALBI = 'SET_UPDATE_PERSONALBI'

export const SET_KR_STATE_LIST = 'SET_KR_STATE_LIST'

export const SET_REOPEN_HISTORY_COMMENT_ID = 'SET_REOPEN_HISTORY_COMMENT_ID'

export const SET_HISTORY_COMMENT_VISIBLE = 'SET_HISTORY_COMMENT_VISIBLE'

export const SET_SUSPENSION_VISIBLE = 'SET_SUSPENSION_VISIBLE'

export const SET_CLICK_SUSPENSION_DATA_PANEL = 'SET_CLICK_SUSPENSION_DATA_PANEL'

export const SET_PERMISSION_OBJ_ID = 'SET_PERMISSION_OBJ_ID'

export const SET_PERMISSION_MODAL_VISIBLE = 'SET_PERMISSION_MODAL_VISIBLE'

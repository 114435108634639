import fee from '@forchange/fee-sdk'
import env from '@/config/env'

// 详情查看 https://git.forchange.cn/web/sdk/fee-sdk

export default () => {
  if (env.ENV === 'dev' || env.ENV === 'local') {
    return
  }
  fee.set({
    pid: 'okr-frontend',
    uuid: '',
    ucid: '',
    target_url: 'https://prod.pandateacher.com/manage-web/fee-node/api',
    record: {
      time_on_page: true,
      performance: true,
      js_error: true,
      js_error_report_config: {
        ERROR_RUNTIME: true,
        ERROR_SCRIPT: true,
        ERROR_STYLE: true,
        ERROR_IMAGE: true,
        ERROR_AUDIO: true,
        ERROR_VIDEO: true,
        ERROR_CONSOLE: true,
        ERROR_TRY_CATCH: true,
        checkErrrorNeedReport: () => true
      }
    },
    getPageType: (location: Location) => `${location.host}${location.pathname}`
  })
}

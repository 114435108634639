import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import EvaluatePlugin from '@forchange/evaluate'
import '@forchange/evaluate/dist/evaluate.css'

import { getUserId, to } from '@library/utils'
import { IProps, IApiSenceData, ISceneInfo, IOptionItem, ITagItem } from './types'
import {
  setCreatedObject,
  setChangedProgress,
  setAlignment,
  setOkrTimeEnough,
  clearOkrState
} from '@store/evaluate/okr/actions'
import * as okrTypes from '@store/evaluate/okr/actionTypes'
import {
  setMapTimeEnough,
  setClickCard,
  setShowSub,
  clearMapState
} from '@store/evaluate/map/actions'
import * as mapTypes from '@store/evaluate/map/actionTypes'
import {
  setProgressTimeEnough,
  setProgressDrawer,
  setPartakeTimeEnough,
  setPartakeDrawer,
  clearTeamState
} from '@store/evaluate/team/actions'
import * as teamTypes from '@store/evaluate/team/actionTypes'

const sceneInfo: ISceneInfo = {}
const tagDictionary: { [key: string]: number } = {}

/**
 * 满意度插件调用控制组件：
 *    条件状态同步于 localStorage 和 redux 中（便于状态重拾与组件刷新）
 *    点击插件关闭按钮，清空所有条件状态（localStorage 和 redux），重新统计
 *    点击插件不再显示按钮，将状态保存至 localStorage
 */

const Evaluate = (props: IProps) => {
  const user_id = getUserId()
  const [isInit, setIsInit] = useState(false)
  /** 初始化：请求情景 id ，重拾用户状态，同步至 redux */
  useEffect(() => {
    !isInit &&
      to(window.apis.getEvaluateId()).then(([err, res]: any) => {
        if (err) {
          return
        }
        res.data.forEach((item: IApiSenceData) => {
          sceneInfo[item.scene_url] = item
        })
        setIsInit(true)
      })
    recollectState()
  }, [user_id])
  /** 插件弹出条件控制 */
  useEffect(() => {
    if (isInit) {
      const { route } = props
      switch (route) {
        case 'okr': {
          const { createdObject, alignment, okrTimeEnough, changedProgress } = props
          if (createdObject && alignment && okrTimeEnough && changedProgress) {
            getDataAndShow(sceneInfo[route].template_id)
          }
          break
        }
        case 'map': {
          const { mapTimeEnough, clickCard, showSub } = props
          if (mapTimeEnough && clickCard && showSub) {
            getDataAndShow(sceneInfo[route].template_id)
          }
          break
        }
        case 'team-okr': {
          const {
            progressTimeEnough,
            progressDrawer,
            partakeTimeEnough,
            partakeDrawer
          } = props
          if (
            progressTimeEnough &&
            progressDrawer &&
            partakeTimeEnough &&
            partakeDrawer
          ) {
            getDataAndShow(sceneInfo[route].template_id)
          }
          break
        }
      }
    }
  }, [
    isInit,
    props.route,
    props.createdObject,
    props.changedProgress,
    props.alignment,
    props.okrTimeEnough,
    props.mapTimeEnough,
    props.clickCard,
    props.showSub,
    props.progressTimeEnough,
    props.progressDrawer,
    props.partakeTimeEnough,
    props.partakeDrawer
  ])
  /** 请求评价数据并展示插件：根据当前路由匹配当前情景 id */
  const getDataAndShow = (template_id: string): void => {
    const { route } = props
    window.apis
      .getEvaluateContent({
        params: { template_id, user_id }
      })
      .then((res: IResponse<any>) => {
        if (!res.errcode && res.data.is_fill === 0) {
          const { title, options, placeholder } = res.data
          const handledOptions = options.map((item: IOptionItem) => ({
            description: item.desc,
            tags: item.tags.map((tagItem: ITagItem) => {
              const { desc, id } = tagItem
              tagDictionary[desc] = id
              return desc
            })
          }))
          // 调用组件
          EvaluatePlugin.show({
            title,
            options: handledOptions,
            textareaPlaceholder: placeholder,
            onsubmit: params => {
              const { rating, tags, textContent } = params
              const select_tags = tags.map((item: string) => Number(tagDictionary[item]))
              window.apis.postEvaluate({
                data: {
                  user_id,
                  template_id,
                  star: rating,
                  select_tags,
                  content: textContent
                }
              })
            },
            onclose: () => {
              clearState(route)
            },
            onclicknotshowagain: () => {
              localStorage.setItem(`NOT_SHOW_AGAIN_${route}_${user_id}`, 'done')
            }
          })
        }
      })
  }
  /** 重拾用户已达成条件 */
  const recollectState = () => {
    // okr
    props.setCreatedObject(!!localStorage.getItem(`${okrTypes.createdObject}_${user_id}`))
    props.setChangedProgress(
      !!localStorage.getItem(`${okrTypes.changedProgress}_${user_id}`)
    )
    props.setAlignment(!!localStorage.getItem(`${okrTypes.alignment}_${user_id}`))
    props.setOkrTimeEnough(!!localStorage.getItem(`${okrTypes.okrTimeEnough}_${user_id}`))
    // map
    props.setMapTimeEnough(!!localStorage.getItem(`${mapTypes.mapTimeEnough}_${user_id}`))
    props.setClickCard(!!localStorage.getItem(`${mapTypes.clickCard}_${user_id}`))
    props.setShowSub(!!localStorage.getItem(`${mapTypes.showSub}_${user_id}`))
    // team-okr
    props.setProgressTimeEnough(
      !!localStorage.getItem(`${teamTypes.progressTimeEnough}_${user_id}`)
    )
    props.setProgressDrawer(
      !!localStorage.getItem(`${teamTypes.progressDrawer}_${user_id}`)
    )
    props.setPartakeTimeEnough(
      !!localStorage.getItem(`${teamTypes.partakeTimeEnough}_${user_id}`)
    )
    props.setPartakeDrawer(
      !!localStorage.getItem(`${teamTypes.partakeDrawer}_${user_id}`)
    )
  }
  /** 清空条件状态 */
  const clearState = (route: string) => {
    switch (route) {
      case 'okr': {
        props.clearOkrState()
        break
      }
      case 'map': {
        props.clearMapState()
        break
      }
      case 'team-okr': {
        props.clearTeamState()
        break
      }
    }
  }
  return null
}

const mapStateToProps = (state: any) => ({
  createdObject: state.evaluateOkr.createdObject,
  changedProgress: state.evaluateOkr.changedProgress,
  alignment: state.evaluateOkr.alignment,
  okrTimeEnough: state.evaluateOkr.okrTimeEnough,
  mapTimeEnough: state.evaluateMap.mapTimeEnough,
  clickCard: state.evaluateMap.clickCard,
  showSub: state.evaluateMap.showSub,
  progressTimeEnough: state.evaluateTeam.progressTimeEnough,
  progressDrawer: state.evaluateTeam.progressDrawer,
  partakeTimeEnough: state.evaluateTeam.partakeTimeEnough,
  partakeDrawer: state.evaluateTeam.partakeDrawer
})

const mapDispatchToProps = (dispatch: any) => ({
  setCreatedObject: (state: boolean) => dispatch(setCreatedObject(state)),
  setChangedProgress: (state: boolean) => dispatch(setChangedProgress(state)),
  setAlignment: (state: boolean) => dispatch(setAlignment(state)),
  setOkrTimeEnough: (state: boolean) => dispatch(setOkrTimeEnough(state)),
  clearOkrState: () => dispatch(clearOkrState()),
  setMapTimeEnough: (state: boolean) => dispatch(setMapTimeEnough(state)),
  setClickCard: (state: boolean) => dispatch(setClickCard(state)),
  setShowSub: (state: boolean) => dispatch(setShowSub(state)),
  clearMapState: () => dispatch(clearMapState()),
  setProgressTimeEnough: (state: boolean) => dispatch(setProgressTimeEnough(state)),
  setProgressDrawer: (state: boolean) => dispatch(setProgressDrawer(state)),
  setPartakeTimeEnough: (state: boolean) => dispatch(setPartakeTimeEnough(state)),
  setPartakeDrawer: (state: boolean) => dispatch(setPartakeDrawer(state)),
  clearTeamState: () => dispatch(clearTeamState())
})

export default connect(mapStateToProps, mapDispatchToProps)(Evaluate)

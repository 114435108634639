import { handleActions } from 'redux-actions'
import * as types from './actionTypes'
import { IState } from './type'

const initialState: IState = {
  createdObject: false,
  changedProgress: false,
  alignment: false,
  okrTimeEnough: false,
  okrDuration: 20000
}

const reducer = handleActions(
  {
    [`${types.createdObject}`]: (state, action: any) => ({
      ...state,
      createdObject: action.payload.data.state
    }),
    [`${types.changedProgress}`]: (state, action: any) => ({
      ...state,
      changedProgress: action.payload.data.state
    }),
    [`${types.alignment}`]: (state, action: any) => ({
      ...state,
      alignment: action.payload.data.state
    }),
    [`${types.okrTimeEnough}`]: (state, action: any) => ({
      ...state,
      okrTimeEnough: action.payload.data.state
    }),
    [`${types.clearState}`]: state => ({
      ...state,
      createdObject: false,
      changedProgress: false,
      alignment: false,
      okrTimeEnough: false
    })
  },
  initialState
)

export default reducer

import * as React from 'react'

import { Spin } from 'antd'

import '../commonLoad/loading.less'

function CommonLoad(props: any) {
  return (
    <div styleName="common-loading">
      <Spin tip={props.text} size="large" />
    </div>
  )
}
export default CommonLoad

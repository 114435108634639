import Apis from '@forchange/apis'
import {
  getTokenKey,
  getToken,
  parseQuery,
  setCookie,
  unsetCookie,
  getCookie
} from './utils'

import env from '../config/env'
import serverMap from '../config/apis'

// 整理 apiMap
const apiMap = {}
try {
  const modules = require.context(`../modules`, true, /.*\/apis\.tsx?$/)
  const components = require.context(`../components`, true, /.*\/apis\.tsx?$/)

  modules.keys().forEach((key: string) => {
    const config = modules(key).default
    Object.assign(apiMap, config)
  })

  components.keys().forEach((key: string) => {
    const config = components(key).default
    Object.assign(apiMap, config)
  })
} catch (err) {
  console.error(err.message)
}

// 整理 serverMap
for (const key of Object.keys(serverMap)) {
  let baseURL = serverMap[key].baseMap[env.ENV]

  if (env.ENV === 'dev' || env.ENV === 'stage') {
    baseURL = `${baseURL}${window.CI_DEV_BRANCH}`
  }

  // host
  const dHost = parseQuery('host')
  if (dHost) {
    baseURL = dHost
  }

  serverMap[key].baseURL = baseURL
}
// 重新登录
function reLogin() {
  window.router.push({
    pathname: '/login',
    state: { name: window.location.hash.split('#')[1] }
  })
}
Apis.useReq((config: any) => {
  const access_token = localStorage.getItem('access_token')
  if (access_token) {
    config.headers.Authorization = access_token
  }
  return config
})
let refreshing = true // 判断是否该请求需要刷新 token
Apis.useRes(
  (res: any) => res.data,
  (err: any) => {
    console.log(err)
    if (err.response) {
      switch (err.response.status) {
        case 401:
          const refresh_token = localStorage.getItem('refresh_token')
          const user_info = localStorage.getItem('user_info')
          let user_id
          if (user_info) {
            user_id = JSON.parse(user_info).id
          }
          // 判断是否有 refresh_token 有的话就晋升为 access_token, 没有的话就跳转到登录页
          if (refresh_token) {
            localStorage.setItem('access_token', refresh_token)
          } else {
            reLogin()
            return Promise.reject(err)
          }
          if (refreshing) {
            refreshing = false
            window.apis
              .refreshToken({
                data: {
                  user_id,
                  token: refresh_token
                }
              })
              .then((res: any) => {
                // 判断 refresh_token 是否过期, 用 errcode 判断
                // 0 代表 token 有效, 1 代表 token 失效
                if (res.errcode) {
                  // 过期则跳转到登录页
                  reLogin()
                } else {
                  refreshing = true
                  localStorage.setItem('refresh_token', res.data.refresh_token)
                  location.reload()
                }
              })
          }
          break
      }
    }

    return Promise.reject(err)
  }
)

const apis = new Apis(serverMap, apiMap)

export default apis

export default {
  getUserList: {
    method: 'get',
    url: '/api/okr/users'
  },
  getHelpInfo: {
    method: 'get',
    url: '/api/okr/help'
  }
}

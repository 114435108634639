import * as React from 'react'
import { withRouter } from 'react-router-dom'

const Okr = withRouter(React.lazy(() => import('./pages/okr')))
const TeamOkr = withRouter(React.lazy(() => import('./pages/teamOkr')))
const Map = withRouter(React.lazy(() => import('./pages/map')))

export default [
  {
    path: '/okr/:user_id?/:period_id?',
    component: () => <Okr />
  },
  {
    path: '/team-okr',
    component: () => <TeamOkr />
  },
  {
    path: '/map/:user_id?/:period_id?',
    component: () => <Map />
  }
]

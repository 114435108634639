import { handleActions } from 'redux-actions'
import * as types from './actionTypes'
import { IState } from './type'

const initialState: IState = {
  currentTab: '',
  currentId: 0,
  periodId: -1,
  currentDate: '',
  updateOkr: false
}

export default handleActions<IState, any>(
  {
    [`${types.SET_CURRENT_TAB}`]: (state: IState, action: any) => ({
      ...state,
      currentTab: action.payload.data.tab
    }),
    [`${types.SET_CURRENT_ID}`]: (state: IState, action: any) => ({
      ...state,
      currentId: action.payload.data.id
    }),
    [`${types.SET_PERIOD_ID}`]: (state: IState, action: any) => ({
      ...state,
      periodId: action.payload.data.id
    }),
    [`${types.SET_CURRENT_DATE}`]: (state: IState, action: any) => ({
      ...state,
      currentDate: action.payload.data.date
    }),
    [`${types.SET_UPDATE_OKR}`]: (state: IState, action: any) => ({
      ...state,
      updateOkr: action.payload.data.update
    })
  },
  initialState
)

import { createAction } from 'redux-actions'
import * as types from './actionTypes'

export const setCurrentTab = createAction(types.SET_CURRENT_TAB, (tab: string) => ({
  data: {
    tab
  }
}))

export const setCurrentId = createAction(types.SET_CURRENT_ID, (id: number) => ({
  data: {
    id
  }
}))

export const setPeriodId = createAction(types.SET_PERIOD_ID, (id: number) => ({
  data: {
    id
  }
}))

export const setCurrentDate = createAction(types.SET_CURRENT_DATE, (date: string) => ({
  data: {
    date
  }
}))

export const setUpdateOkr = createAction(types.SET_UPDATE_OKR, (update: boolean) => ({
  data: {
    update
  }
}))

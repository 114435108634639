import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { setScreenSize } from '@store/resize/actions'

export default function(Comp: any) {
  return function() {
    return (
      <ScreenResizeWithRedux>
        <Comp />
      </ScreenResizeWithRedux>
    )
  }
}

interface IProps {
  setScreenSize: (size: IScreenSize) => void
  children: any
}

/**
 * 为 window 添加 resize 事件监听，并保存状态至 redux 中
 */
function ScreenResize(props: IProps) {
  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  const handleWindowResize = (event?: any) => {
    const { innerWidth } = event ? event.target : window
    const { setScreenSize } = props
    if (innerWidth >= 1440) {
      setScreenSize('large')
    } else {
      setScreenSize('little')
    }
  }

  return props.children
}

const mapDispatchToProps = (dispatch: any) => ({
  setScreenSize: (size: IScreenSize) => dispatch(setScreenSize(size))
})

const ScreenResizeWithRedux = connect(undefined, mapDispatchToProps)(ScreenResize)

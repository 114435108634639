import { handleActions } from 'redux-actions'
import * as types from './actionTypes'
import { IState } from './type'

const initialState: IState = {
  authority: false,
  modalVisible: false,
  modalTitle: '',
  deleteId: 0,
  deleteKrRemarkState: false,
  weightModal: false,
  keyResultList: [],
  keyResultId: 0,
  alignModal: false,
  alignHistory: {},
  alignStatus: false,
  objectiveId: 0,
  clearSearch: false,
  isUpdateBI: false,
  krStateList: [],
  krStateColor: {},
  reopenHistoryCommentId: { objId: -1, commentId: -1 },
  historyCommentVisible: false,
  suspensionVisible: false,
  clickSuspensionDataPanel: false,
  permissionObjId: 0,
  permissionModalVisible: false
}

export default handleActions<IState, any>(
  {
    [`${types.SET_AUTHORITY}`]: (state: any, action: any) => ({
      ...state,
      authority: action.payload.data.data
    }),
    [`${types.SET_MODAL}`]: (state: any, action: any) => ({
      ...state,
      modalVisible: action.payload.data.visible
    }),
    [`${types.SET_MODAL_TITLE}`]: (state: any, action: any) => ({
      ...state,
      modalTitle: action.payload.data.title
    }),
    [`${types.SET_DELETE_ID}`]: (state: any, action: any) => ({
      ...state,
      deleteId: action.payload.data.id
    }),
    [`${types.SET_DELETE_KR_REMARK_STATE}`]: (state: any, action: any) => ({
      ...state,
      deleteKrRemarkState: action.payload.data.state
    }),
    [`${types.SET_WEIGHT_MODAL}`]: (state: any, action: any) => ({
      ...state,
      weightModal: action.payload.data.visible
    }),
    [`${types.GET_WEIGHT_LIST}_FULFILLED`]: (state: any, action: any) => ({
      ...state,
      keyResultList: action.payload.data.kr_info
    }),
    [`${types.SET_KR_ID}`]: (state: any, action: any) => ({
      ...state,
      keyResultId: action.payload.data.id
    }),
    [`${types.SET_ALIGN_MODAL}`]: (state: any, action: any) => ({
      ...state,
      alignModal: action.payload.data.visible
    }),
    [`${types.GET_ALIGN_HISTORY}_FULFILLED`]: (state: any, action: any) => ({
      ...state,
      alignHistory: action.payload.data
    }),
    [`${types.SET_UPDATE_SIDE}`]: (state: any, action: any) => ({
      ...state,
      alignStatus: action.payload.data.update
    }),
    [`${types.SET_OBJ_ID}`]: (state: any, action: any) => ({
      ...state,
      objectiveId: action.payload.data.objId
    }),
    [`${types.SET_ClEAR_SEARCH}`]: (state: any, action: any) => ({
      ...state,
      clearSearch: action.payload.data.isClear
    }),
    [`${types.SET_UPDATE_PERSONALBI}`]: (state: any, action: any) => ({
      ...state,
      isUpdateBI: action.payload.data.update
    }),
    [`${types.SET_KR_STATE_LIST}`]: (state: any, action: any) => ({
      ...state,
      krStateList: action.payload.data.list,
      krStateColor: action.payload.data.dictionary
    }),
    [`${types.SET_REOPEN_HISTORY_COMMENT_ID}`]: (state: any, action: any) => ({
      ...state,
      reopenHistoryCommentId: action.payload.data.id
    }),
    [`${types.SET_HISTORY_COMMENT_VISIBLE}`]: (state: any, action: any) => ({
      ...state,
      historyCommentVisible: action.payload.data.visible
    }),
    [`${types.SET_SUSPENSION_VISIBLE}`]: (state: any, action: any) => ({
      ...state,
      suspensionVisible: action.payload.data.visible
    }),
    [`${types.SET_CLICK_SUSPENSION_DATA_PANEL}`]: (state: any, action: any) => ({
      ...state,
      clickSuspensionDataPanel: action.payload.data.state
    }),
    [`${types.SET_PERMISSION_OBJ_ID}`]: (state: any, action: any) => ({
      ...state,
      permissionObjId: action.payload.data.id
    }),
    [`${types.SET_PERMISSION_MODAL_VISIBLE}`]: (state: any, action: any) => ({
      ...state,
      permissionModalVisible: action.payload.data.visible
    })
  },
  initialState
)

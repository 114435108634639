import { isNotEmptyArray } from '@/library/utils'

export function isAryVal(arr: any) {
  return Array.isArray(arr) && arr.length > 0
}

export function limitDecimals(value: any) {
  const reg = /^(\-)*(\d+)\.(\d).*$/
  if (typeof value === 'string') {
    return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : ''
  } else if (typeof value === 'number') {
    return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : ''
  } else {
    return ''
  }
}

export function limitInteger(value: any) {
  const reg = /^(\-)*(\d+)\.*$/
  if (typeof value === 'string') {
    return !isNaN(Number(value)) ? value.replace(reg, '$1$2') : ''
  } else if (typeof value === 'number') {
    return !isNaN(value) ? String(value).replace(reg, '$1$2') : ''
  } else {
    return ''
  }
}

export function debounce(func: any, wait: number, immediate: boolean) {
  let timeout: any, result: any

  let debounced = function(this: any) {
    let context = this
    let args = arguments

    if (timeout) clearTimeout(timeout)
    if (immediate) {
      // 如果已经执行过，不再执行
      let callNow = !timeout
      timeout = setTimeout(function() {
        timeout = null
      }, wait)
      if (callNow) result = func.apply(context, args)
    } else {
      timeout = setTimeout(function() {
        func.apply(context, args)
      }, wait)
    }
    return result
  }

  return debounced
}
/*
滚动到顶部的动画
*/
export function backTop(ele: any) {
  let timer: any = null
  cancelAnimationFrame(timer)
  timer = requestAnimationFrame(function fn() {
    if (ele.scrollTop > 0) {
      ele.scrollTop = ele.scrollTop - 50
      timer = requestAnimationFrame(fn)
    } else {
      cancelAnimationFrame(timer)
    }
  })
}
/*
滚动到底部的动画
*/
export function scrollBottom(ele: any, warp: any) {
  // 获取滚动到底部的距离
  let distance = warp.scrollHeight - ele.clientHeight
  let timer: any = null
  cancelAnimationFrame(timer)
  timer = requestAnimationFrame(function fn() {
    if (ele.scrollTop < distance) {
      ele.scrollTop = ele.scrollTop + 20
      timer = requestAnimationFrame(fn)
    } else if (ele.scrollTop === distance) {
      cancelAnimationFrame(timer)
    }
  })
}
/*
字符串分数转为百分数
*/
export function stringFractionToNumber(str: string) {
  const numberArr = str.split('/')
  return (Number(numberArr[0]) / Number(numberArr[1])) * 100
}

export function handlePeriodList(date: string) {
  const currentYears = date.split('-')[0]
  const currentMonth = date.split('-')[1]
  return `${currentYears}年${currentMonth}月-${Number(currentMonth) + 1}月`
}

/**
 * @summary 格式化即将发送的评论内容
 * @param content 原始评论内容
 * @param atMenData @ 数据
 * @param pasteUrlData 复制 URL 链接数据
 * @param oldLinkData 当编辑旧评论时的旧 a 标签数据
 */
export const transferComment = (
  content: string,
  atMenData: {
    atMen: IAtMen[]
    periodId: number
  },
  pasteUrlData: {
    pasteUrlArr: string[]
  },
  oldLinkData?: {
    oldCommentLinkArr: IOldCommentLink[]
  }
) => {
  const { atMen, periodId } = atMenData
  const { pasteUrlArr } = pasteUrlData
  if (oldLinkData) {
    const { oldCommentLinkArr } = oldLinkData
    content = transferOldLink(content, oldCommentLinkArr)
  }
  content = transferPasteUrl(content, pasteUrlArr)
  content = transferAt(content, atMen, periodId)
  content = content.replace(/\n/g, '<br />')
  return content
}

const transferAt = (content: string, atMen: IAtMen[], periodId: number) => {
  const env = window.ENV ? window.ENV.toLowerCase() : 'local'
  const baseUrl = {
    localprod: 'https://localprod.pandateacher.com/ninth-studio-okr/frontend',
    prod: 'https://okr.forchange.cn',
    stage: `https://stage.pandateacher.com/ninth-studio-okr/frontend${window.CI_DEV_BRANCH}`,
    test: 'https://test.pandateacher.com/ninth-studio-okr/frontend',
    dev: `https://dev.pandateacher.com/ninth-studio-okr/frontend${window.CI_DEV_BRANCH}`,
    local: 'http://local.pandateacher.tech:8080'
  }
  let comment = content

  // 去重
  const tempAtMen = atMen.reduce((arr: IAtMen[], item) => {
    if (!arr.some(el => el.job_no === item.job_no)) {
      arr.push(item)
    }
    return arr
  }, [])

  tempAtMen.forEach((ele: IAtMen) => {
    const reg = new RegExp(`@${ele.name}-${ele.job_no} `, 'g')
    comment = comment.replace(
      reg,
      `<a target='_blank' href='${baseUrl[env as keyof typeof baseUrl]}/#/okr/${
        ele.id
      }/${periodId}'>@${ele.name} </a>`
    )
  })

  return comment
}

const transferPasteUrl = (content: string, pasteUrlArr: string[]) => {
  pasteUrlArr.forEach(item => {
    content = content.replace(item, `<a target='_blank' href="${item}">${item}</a>`)
  })
  return content
}

const transferOldLink = (content: string, oldCommentLinkArr: IOldCommentLink[]) => {
  oldCommentLinkArr.forEach(item => {
    content = content.replace(item.origin, item.result)
  })
  return content
}

// 从评论中提取 at 的人
export const getAtMenfromContent = (content: string) => {
  const reg = /<a target=\'_blank\' href=\'.*?okr\/\d*?\/\d*?\'>@.*?<\/a>/g
  const result = content.match(reg)
  const atMen = result?.map((ele: string) => {
    return {
      id: ele.match(/\/\d+?\//)?.[0]?.split('/')[1],
      name: ele
        .match(/@.*?</)?.[0]
        .split('@')[1]
        ?.slice(0, -2)
    }
  })
  return atMen || []
}

// 格式化评论内容并返回已有 a 标签数据
export const formatEditComment = (content: string): [string, IOldCommentLink[]] => {
  const aTagReg = /<a(.|\n|\r)*?>(.+)<\/a>/g
  const aFistTagReg = /<a(.|\n|\r)*?>/g
  const aLastTagReg = /<\/a>/g
  const oldCommentLink: IOldCommentLink[] = []
  content = content?.replace(aTagReg, str => {
    oldCommentLink.push({
      origin: str.replace(aFistTagReg, '').replace(aLastTagReg, ''),
      result: str
    })
    return str
  })
  content = removeAtMenLink(content)
  return [content, oldCommentLink]
}

// 链接标签移除
export const removeAtMenLink = (content: string) => {
  const firstReg = /<a(.|\n|\r)*?>/g
  const secondReg = /<\/a>/g
  const thirdReg = /<br \/>/g
  let result = content?.replace(firstReg, '')
  result = result.replace(secondReg, '')
  result = result.replace(thirdReg, '\n')
  return result
}

// 删除 at_lit 中被删除了的人
export const deleteAtMentItem = (content: string, atMen: IAtMen[]) => {
  return atMen.filter((man: IAtMen) => {
    return content.includes(`@${man.name}`)
  })
}

import { createHashHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './app'
import apis from '../library/apis'
import setFee from '@/library/fee'

window.apis = apis
window.router = createHashHistory()
setFee()

ReactDOM.render(<App />, document.getElementById('app'))

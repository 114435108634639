import { handleActions } from 'redux-actions'
import * as types from './actionTypes'
import { IState } from './type'

const initialState: IState = {
  screenSize: 'large'
}

const reducer = handleActions(
  {
    [`${types.screenSize}`]: (state, action: any) => ({
      ...state,
      screenSize: action.payload.data.size
    })
  },
  initialState
)

export default reducer

import React from 'react'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import promise from 'redux-promise-middleware'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import thunk from 'redux-thunk'

import reducers from '../store/reducers'
import './../style/reset.css'
import './../style/global.less'
import RouterComponent from './router'

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk, promise))
)

declare global {
  type RootStateType = ReturnType<typeof store.getState>
}

class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
  }

  render(): JSX.Element {
    return (
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <RouterComponent />
        </Provider>
      </ConfigProvider>
    )
  }
}

export default hot(App)

import { handleActions } from 'redux-actions'
import * as types from './actionTypes'
import { IState } from './type'

const initialState: IState = {
  progressTimeEnough: false,
  progressDrawer: false,
  partakeTimeEnough: false,
  partakeDrawer: false,
  teamDuration: 30000
}

const reducer = handleActions(
  {
    [`${types.progressTimeEnough}`]: (state, action: any) => ({
      ...state,
      progressTimeEnough: action.payload.data.state
    }),
    [`${types.progressDrawer}`]: (state, action: any) => ({
      ...state,
      progressDrawer: action.payload.data.state
    }),
    [`${types.partakeTimeEnough}`]: (state, action: any) => ({
      ...state,
      partakeTimeEnough: action.payload.data.state
    }),
    [`${types.partakeDrawer}`]: (state, action: any) => ({
      ...state,
      partakeDrawer: action.payload.data.state
    }),
    [`${types.clearState}`]: state => ({
      ...state,
      progressTimeEnough: false,
      progressDrawer: false,
      partakeTimeEnough: false,
      partakeDrawer: false
    })
  },
  initialState
)

export default reducer

// 自定义hooks
import React, { useState, useEffect, useRef } from 'react'

/**
 * 模拟class组件的this.setState
 *
 * @param {T} initialState 初始化数据
 * @return {Array<T | any>} 返回初始化数据和改变该数据的方法
 */
export function useXState<T>(initialState: T): Array<T | any> {
  const [state, setState] = useState<T>(initialState)
  const isUpdate: any = useRef()

  const setXState = (state: T, cb?: Function) => {
    setState((pre: T) => {
      isUpdate.current = cb

      return typeof state === 'function' ? state(pre) : state
    })
  }
  useEffect(() => {
    if (isUpdate.current) {
      isUpdate.current()
      isUpdate.current = null
    }
  }, [state])

  return [state, setXState]
}
/**
 * 自定义浏览器标题
 *
 * @param {string} title 初始化标题
 *
 */
export function useTitle(title: string) {
  useEffect(() => {
    document.title = title
  }, [])

  return
}

/**
 * 强制组件渲染
 *
 *
 * @return {Function} 返回更新组件的方法
 */
export function useUpdate() {
  const [, setFlag] = useState()
  const update = () => {
    setFlag(Date.now())
  }

  return update
}

import { createAction } from 'redux-actions'
import * as types from './actionTypes'
import { getUserId } from '@library/utils'

export const setCreatedObject = createAction(types.createdObject, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.createdObject}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const setChangedProgress = createAction(
  types.changedProgress,
  (state: boolean) => {
    const user_id = getUserId()
    user_id &&
      state &&
      localStorage.setItem(`${types.changedProgress}_${user_id}`, 'done')
    return {
      data: { state }
    }
  }
)

export const setAlignment = createAction(types.alignment, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.alignment}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const setOkrTimeEnough = createAction(types.okrTimeEnough, (state: boolean) => {
  const user_id = getUserId()
  user_id && state && localStorage.setItem(`${types.okrTimeEnough}_${user_id}`, 'done')
  return {
    data: { state }
  }
})

export const clearOkrState = createAction(types.clearState, () => {
  const user_id = getUserId()
  localStorage.removeItem(`${types.createdObject}_${user_id}`)
  localStorage.removeItem(`${types.changedProgress}_${user_id}`)
  localStorage.removeItem(`${types.alignment}_${user_id}`)
  localStorage.removeItem(`${types.okrTimeEnough}_${user_id}`)
})

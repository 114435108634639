import React, { useEffect, useState, useRef } from 'react'

import { connect } from 'react-redux'
import { Menu, message } from 'antd'
const { SubMenu } = Menu

import Avatar from '../Avatar/Avatar'
import { setClearSearch, setUpdateSide } from '@/store/okr/actions'
import { setCurrentTab } from '@store/menu/actions'
import { useUpdate } from '@/hooks/hooks'
import { parseUrlQuery, to } from '@/library/utils'
import { isAryVal } from '../../modules/home/util'

import './menu.less'
import myOkr from './assets/myokr.svg'
import myOkrSelected from './assets/myokrselected.svg'
import teamOkr from './assets/teamokr.svg'
import teamOkrSelected from './assets/teamokrselected.svg'
import superior from './assets/superior.svg'
import superiorSelected from './assets/superiorselected.svg'
import similar from './assets/similar.svg'
import similarSelected from './assets/similarselected.svg'
import lower from './assets/lower.svg'
import lowerSelected from './assets/lowerselected.svg'
import follow from './assets/follow.svg'
import followSelected from './assets/followselected.svg'
import align from './assets/align.svg'
import alignSelected from './assets/alignselected.svg'

interface IProps {
  currentId: number
  periodId: number
  currentTab: string
  clearSearch: boolean
  alignStatus: boolean
  screenSize: IScreenSize
  setClearSearch: (isClear: boolean) => void
  setUpdateSide: (update: boolean) => void
  setCurrentTab: (tab: string) => void
}

interface IMenu {
  key: string
  title: string
  items: string[]
  icon: any
  icon_selected: any
}

const menuItem = [
  {
    key: 'superior_info',
    title: '我的上级',
    items: [],
    icon: superior,
    icon_selected: superiorSelected
  },
  {
    key: 'similar_info',
    title: '我的同级',
    items: [],
    icon: similar,
    icon_selected: similarSelected
  },
  {
    key: 'lower_info',
    title: '我的下级',
    items: [],
    icon: lower,
    icon_selected: lowerSelected
  },
  {
    key: 'align_info',
    title: '与我对齐',
    items: [],
    icon: align,
    icon_selected: alignSelected
  },
  {
    key: 'follow_info',
    title: '我的关注',
    items: [],
    icon: follow,
    icon_selected: followSelected
  }
]
const SideMenu = (props: IProps) => {
  const myId = useRef(0)
  const [isSuperior, setIsSuperior] = useState<number>(0) // 是否显示团队数据
  const [selectItem, setSelectItem] = useState<string>('')
  const update = useUpdate()
  useEffect(() => {
    const userInfo: any = localStorage.getItem('user_info')
    window.addEventListener('hashchange', handleHash, false)
    window.addEventListener('popstate', handleBack, false)
    if (userInfo) {
      message.config({
        top: 86
      })
      const userId = JSON.parse(userInfo).id
      const isSuperior = JSON.parse(userInfo).is_superior
      myId.current = userId
      setIsSuperior(isSuperior)
      const tab = sessionStorage.getItem('tab')
      let queryTab
      let paramsUserId = Number(window.location.hash.split('/')[2]) || userId
      if (window.location.hash.split('?')[1]) {
        queryTab = parseUrlQuery('tab')
      }
      if (tab) {
        if (queryTab === 'lower-info') {
          setSelectItem('null')
        } else {
          setSelectItem(tab)
        }
      } else {
        if (paramsUserId !== userId) {
          setSelectItem('null')
        } else {
          setSelectItem(`${userId}`)
        }
      }
    }
    return () => {
      window.removeEventListener('hashchange', handleHash, false)
      window.removeEventListener('popstate', handleBack, false)
    }
  }, [])
  useEffect(() => {
    if (props.currentTab) {
      setSelectItem(props.currentTab)
      sessionStorage.setItem('tab', props.currentTab)
    }
  }, [props.currentTab])
  // 切换周期时更新侧边栏
  useEffect(() => {
    if (props.periodId !== -1) {
      getSideInfo(myId.current, props.periodId)
    }
  }, [props.periodId])
  // 对齐后更新侧边栏
  useEffect(() => {
    if (props.alignStatus) {
      getSideInfo(myId.current, props.periodId)
    }
  }, [props.alignStatus, props.periodId])

  const handleHash = () => {
    window.history.replaceState('hasHash', '', '')
  }

  const handleBack = (e: any) => {
    if (e.state) {
      // 后退时取消侧边栏选中
      setSelectItem(`null${Math.random()}`)
    }
  }

  const getSideInfo = async (userId: number, periodId: number) => {
    const [err, res] = await to(
      window.apis.getSideInfo({
        params: {
          user_id: userId,
          period_id: periodId
        }
      })
    )
    if (!res.errcode) {
      props.setUpdateSide(false)
      const result = res.data
      const { superior_info, similar_info, lower_info, align_info, follow_info } = result
      menuItem[0].items = superior_info
      menuItem[1].items = similar_info
      menuItem[2].items = lower_info
      menuItem[3].items = align_info
      menuItem[4].items = follow_info
      update()
    }
  }

  const handleClickMenu = ({ key }: any) => {
    setSelectItem(key)
    props.setCurrentTab(key)
    // 存储当前tab
    sessionStorage.setItem('tab', key)
    if (key === 'team-okr') {
      window.router.push('/team-okr')
    } else {
      if (key.split('-')[1]) {
        key = Number(key.split('-')[0])
      } else {
        key = Number(key)
      }
      window.router.push(`/okr/${key}/${props.periodId}`)
      if (key !== props.currentId) {
        props.setClearSearch(!props.clearSearch)
      }
    }
  }
  return (
    <div styleName={`menu ${props.screenSize}`}>
      <Menu
        onClick={handleClickMenu}
        mode="inline"
        selectedKeys={[selectItem]}
        defaultOpenKeys={['follow_info']}
      >
        <Menu.Item key={`${myId.current}`}>
          <img
            src={selectItem === `${myId.current}` ? myOkrSelected : myOkr}
            styleName="icon"
          />
        </Menu.Item>
        {isSuperior && (
          <Menu.Item key="team-okr">
            <img
              src={selectItem === 'team-okr' ? teamOkrSelected : teamOkr}
              styleName="icon"
            />
          </Menu.Item>
        )}
        {isAryVal(menuItem) &&
          menuItem.map(
            (menu: IMenu) =>
              (menu.items?.length > 0 || menu.key === 'follow_info') && (
                <SubMenu
                  key={menu.key}
                  title={
                    <img
                      src={selectItem.includes(menu.key) ? menu.icon_selected : menu.icon}
                      styleName="icon"
                    />
                  }
                >
                  {isAryVal(menu.items) &&
                    menu.items.map((item: any) => (
                      <Menu.Item key={`${item.user_id}-${menu.key}`}>
                        <div className="info">
                          <Avatar avatar={item.avatar} name={item.user_name} />
                          <span className="name">{item.user_name}</span>
                        </div>
                      </Menu.Item>
                    ))}
                </SubMenu>
              )
          )}
      </Menu>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  clearSearch: state.okr.clearSearch,
  alignStatus: state.okr.alignStatus,
  currentTab: state.menu.currentTab,
  currentId: state.menu.currentId,
  periodId: state.menu.periodId,
  isSuperior: state.menu.isSuperior,
  screenSize: state.resize.screenSize
})

const mapDispatchToProps = (dispatch: any) => ({
  setClearSearch: (isClear: boolean) => dispatch(setClearSearch(isClear)),
  setUpdateSide: (update: boolean) => dispatch(setUpdateSide(update)),
  setCurrentTab: (tab: string) => dispatch(setCurrentTab(tab))
})

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)

export default {
  // 扫描登录
  postAdminUserDing: {
    // server: 'baseServer',
    method: 'post',
    url: '/api/login'
  },
  refreshToken: {
    method: 'post',
    url: '/api/refresh'
  }
}

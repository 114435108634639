import { combineReducers } from 'redux'
import okr from './okr/reducers'
import menu from './menu/reducers'
import evaluateOkr from './evaluate/okr/reducers'
import evaluateMap from './evaluate/map/reducers'
import evaluateTeam from './evaluate/team/reducers'
import resize from './resize/reducers'

const reducers = combineReducers({
  okr,
  menu,
  evaluateOkr,
  evaluateMap,
  evaluateTeam,
  resize
})

export default reducers
